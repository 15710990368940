import React from "react"
import projectStyles from "./projectcard.module.css"

export default function ProjectCard(props) {
  return (
  <div className={projectStyles.container}>
    <div className={projectStyles.projectText}>
      <p className={projectStyles.title}>{props.titleText}</p>
      <p className={projectStyles.blurb}>{props.blurbText}</p>
      <div className={projectStyles.links}>
        <a href={props.demoLink} target="_blank" rel="noreferrer">
          <span>{props.demoText}</span>
        </a>
        <a href={props.sourceLink} target="_blank" rel="noreferrer">
          <span>{props.sourceText}</span>
        </a>
      </div>
    </div>
    
    <div className={projectStyles.projectImage}>
        <img src={props.imgURL} alt=""></img>
    </div>
    
  </div>
  )
}