import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ProjectCard from "../components/projectcard";

export default function Work() {
	return (
		<>
			<SEO />
			<Layout>
				<ProjectCard
					titleText="lunch buddy"
					blurbText="A lunch appointment scheduling website made to help people feel less lonely when lunch time rolls around. Made with Ruby on Rails, AWS S3, Action Mailer, Devise and Google Maps API."
					demoText="Demo"
					demoLink="https://lunchy-buddy.herokuapp.com/"
					sourceText="Source Code"
					sourceLink="https://github.com/joeyqlim/lunch-buddy"
					imgURL="https://i.imgur.com/2QK2F2Q.png"
				/>
				<ProjectCard
					titleText="good.sg"
					blurbText="Microblog website created to promote good social behaviours in Singapore. Built with HTML, CSS, JavaScript, jQuery, Node.js, Express and EJS. Uses bcrypt for password encryption, Passport.js for user authentication and Cloudinary API for image uploads."
					demoText="Demo"
					demoLink="https://goodsg.herokuapp.com/"
					sourceText="Source Code"
					sourceLink="https://github.com/joeyqlim/good.sg"
					imgURL="https://camo.githubusercontent.com/bda0be59cdcfa9e2bdd114febb4f06d84c5e4db3/68747470733a2f2f692e696d6775722e636f6d2f505047453667562e706e67"
				/>
				<ProjectCard
					titleText="Smartbrain"
					blurbText="Full-stack AI face recognition app built with React.js, Node.js and postgreSQL. Uses Clarifai's facial recognition model and bcrypt for password encryption."
					demoText="Demo"
					demoLink="https://smartbrain-j.herokuapp.com/"
					sourceText="Source Code"
					sourceLink="https://github.com/joeyqlim/smartbrain"
					imgURL="https://camo.githubusercontent.com/67cfec26811c86c3bebc80cf9be6394b41f82a4c/68747470733a2f2f692e696d6775722e636f6d2f4a39377a5350752e6a7067"
				/>
				<ProjectCard
					titleText="Pet the Catto"
					blurbText="Turn-based cat clicker game with 3 difficulty levels, where the aim is not to upset the cat. Built with JavaScript, jQuery, HTML, and SASS."
					demoText="Demo"
					demoLink="https://joeyqlim.github.io/pet-the-catto/"
					sourceText="Source Code"
					sourceLink="https://github.com/joeyqlim/pet-the-catto"
					imgURL="https://camo.githubusercontent.com/9ed1578c83e567d470109870340f21aba68d9629/68747470733a2f2f692e696d6775722e636f6d2f4e304c36426c352e706e67"
				/>
				<ProjectCard
					titleText="DORA"
					blurbText="A travel planning tool for flexible yet organized explorers. Made with the MERN stack, Redux, Semantic UI and JWT authentication."
					demoText="Demo"
					demoLink="https://dora-explora.herokuapp.com/"
					sourceText="Source Code"
					sourceLink="https://github.com/joeyqlim/DORA"
					imgURL="https://i.imgur.com/KTOugSR.png"
				/>
				<ProjectCard
					titleText="Getty Images"
					blurbText="Stock photography portfolio on Getty Images."
					demoText="View"
					demoLink="https://www.gettyimages.com/search/photographer?assettype=image&family=creative&license=rf&photographer=Joey%20Lim&sort=best"
					imgURL="https://live.staticflickr.com/65535/49665225796_6759ebcb89_c.jpg"
				/>
			</Layout>
		</>
	);
}
